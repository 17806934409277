<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update'
          ? $lang.companysize.crud.edit
          : $lang.companysize.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>

      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>

          <div
              class="form-group"
              :class="{ 'form-group--error': $v.companysize.title.$error }"
          >
            <label class="form__label"
            >{{ $lang.companysize.form.title }}
              <required_span/>
            </label>
            <input
                :placeholder="$lang.companysize.form.title"
                class="form-control"
                :maxlength="maxlength.title"
                v-model="companysize.title"
            />
            <small
                class="error"
                v-if="
                $v.companysize.title.$error && !$v.companysize.title.required
              "
            >{{ $lang.companysize.validation.required.title }}</small
            >
            <small
                class="error"
                v-if="
                $v.companysize.title.$error && !$v.companysize.title.minLength
              "
            >{{ $lang.companysize.validation.minLength.title }}
              {{ $v.companysize.title.$params.minLength.min }}
              {{ $lang.companysize.validation.common.letter }}</small
            >
          </div>


          <div
              class="form-group"
              :class="{ 'form-group--error': $v.companysize.from.$error }"
          >
            <label class="form__label"
            >{{ $lang.companysize.form.from }}
              <required_span/>
            </label>
            <input
                :placeholder="$lang.companysize.form.from"
                class="form-control"
                :maxlength="maxlength.from"
                v-model="companysize.from"
            />
            <small
                class="error"
                v-if="
                $v.companysize.from.$error && !$v.companysize.from.required
              "
            >{{ $lang.companysize.validation.required.from }}</small
            >
            <small
                class="error"
                v-if="
                $v.companysize.from.$error && !$v.companysize.from.minLength
              "
            >{{ $lang.companysize.validation.minLength.from }}
              {{ $v.companysize.from.$params.minLength.min }} </small
            >
          </div>

          <div
              class="form-group"
              :class="{ 'form-group--error': $v.companysize.to.$error }"
          >
            <label class="form__label"
            >{{ $lang.companysize.form.to }}
              <required_span/>
            </label>
            <input
                :placeholder="$lang.companysize.form.to"
                class="form-control"
                :maxlength="maxlength.to"
                v-model="companysize.to"
            />
            <small
                class="error"
                v-if="
                $v.companysize.to.$error && !$v.companysize.to.required
              "
            >{{ $lang.companysize.validation.required.to }}</small
            >
            <small
                class="error"
                v-if="
                $v.companysize.to.$error && !$v.companysize.to.minLength
              "
            >{{ $lang.companysize.validation.minLength.title }}
              {{ $v.companysize.title.$params.minLength.min }}</small
            >
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label"
            >{{ $lang.companysize.form.isActive }}
            </label>
            <input
                type="checkbox"
                :placeholder="$lang.companysize.form.isActive"
                :value="1"
                @change="changedIsActive"
                v-model="companysize.isActive"
            />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              :disabled="submitted"
              size="sm"
              color="primary"
          >
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
          &nbsp;
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.companysize.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-company-size')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'CompanySize')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="/company-sizes/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'CompanySize')"
          >
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #title="data">
              <span :title="data.row.title">{{
                  trimfunction(data.row.title, 20)
                }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-company-size')"
              >
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-company-size')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {companysize} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {minLength, required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "companySize",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      isActiveFields: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "",
      messageColor: "success",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: companysize,
      companysize: {
        title: "",
        from: "",
        to: "",
      },
      columns: ["title", "from", 'to', 'status', "actions"],
      maxlength: {
        title: Maxlength.companysize.title,
        from: Maxlength.companysize.from,
        to: Maxlength.companysize.to,
      },
      data: [],
      options: {
        headings: {
          title: this.$lang.companysize.table.title,
          from: this.$lang.companysize.table.from,
          to: this.$lang.companysize.table.to,
          status: this.$lang.common.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["title", "type"],
        sortable: ["title", "type"],
        filterable: ["title", "type"],
      },
    };
  },
  validations: {
    companysize: {
      title: {
        required,
        minLength: minLength(1),
      },
      from: {
        required,
        minLength: minLength(1),
      },
      to: {
        required,
        minLength: minLength(1),
      },
    },
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.companysize.isActive = 1;
      } else {
        self.companysize.isActive = 0;
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.data.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDown = 10;
            self.submitted = false;
          });
        }
      });
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.companysize.title = "";
      self.companysize.from = "";
      self.companysize.to = "";
      self.companysize.isActive = 1;
      self.largeModal = true;
      //this.$router.push({name: 'Country Create'})
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      let id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              let responseData = response.data.data;
              self.companysize.title = responseData.title;
              self.companysize.from = responseData.from;
              self.companysize.to = responseData.to;
              self.companysize.isActive = responseData.isActive;
            } else {
              self.data = [];
            }
          });

      // this.$router.push({path: `country/edit/${(item)}`})
      self.largeModal = true;
    },
    rowClicked(item) {
      this.$router.push({path: `companysize/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },
    onSubmit() {
      let self = this;
      this.$v.companysize.$touch();
      if (this.$v.companysize.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";

        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.companysize.title,
              from: self.companysize.from,
              to: self.companysize.to,
              isActive: self.companysize.isActive,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.message;
                    self.messageColor = "success";
                    self.dismissCountDownS = 10;
                  } else {
                    self.err_msg = response.data.message;
                    self.messageColor = "danger";
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.companysize.title,
              from: self.companysize.from,
              to: self.companysize.to,
              isActive: self.companysize.isActive,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.messageColor = "success";
                    self.dismissCountDownS = 10;
                    self.submitted = false; //Enable Button
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.messageColor = "danger";
                    self.dismissCountDown = 10;
                    self.submitted = false; //Enable Button
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
